import React from 'react'
import styled from 'styled-components'
import sizes from '../_vars/sizes'
import Img from 'gatsby-image'

import '../layouts/global.scss'

const Hero = styled.div`
  width: calc(100% + ${sizes.padding1} * 2);
  margin-left: -${sizes.padding1};
  z-index: 1;

  .gatsby-image-wrapper {
    height: 100%;
  }
`

const MobileImg = styled(Img)`
  display: block;
  @media (min-width: ${sizes.wideScreen}) {
    display: none;
  }
`
const WideImg = styled(Img)`
  display: none;
  @media (min-width: ${sizes.wideScreen}) {
    display: block;
  }
`

export default class HeroImage extends React.Component {
  render() {
    return (
      <Hero>
        <MobileImg alt={this.props.alt} fluid={this.props.mobile} />
        <WideImg alt={this.props.alt} fluid={this.props.wide} />
      </Hero>
    )
  }
}
