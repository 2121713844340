import React from 'react'
import styled from 'styled-components'
import colors from '../_vars/colors'
import sizes from '../_vars/sizes'
// import Img from 'gatsby-image'

const ProcessWrapper = styled.section`
  /* border: green solid 10px; */

  display: grid;
  grid-column: 1/-1;
  background: ${colors.text};
  color: ${colors.lightGray2}; /* darker theme */

  padding: 5vw 50vw;
  margin: 0 -50vw;
  margin-top: ${sizes.paddingXL};

  & > p, 
  & > h1, 
  & > h2,
  & > h3, 
  & > h4 {
    width: 100%;
    max-width: ${sizes.processTextMax};    
    margin: ${sizes.paddingMed} auto;

    /* matches image-caption component on mobile */
    @media (max-width: ${sizes.smScreen}) {
      padding: 0 ${sizes.padding1};
    }
  }

  & > h1, 
  & > h2,
  & > h3, 
  & > h4 {
    margin: ${sizes.paddingXL} auto ${sizes.paddingMed};
  }

  & > p + p, 
  & > h1 + p, 
  & > h2 + p, 
  & > h3 + p {
    margin-top: calc(${sizes.paddingMed} * -0.5);
  }

  h3 {
    font-size: ${sizes.fontHead3};

    @media (max-width: ${sizes.smScreen}) {
      padding: 0 ${sizes.padding1};
    }
  }
  
  a, a:visited {
    color: inherit;
  }

    /* @media (min-width: ${sizes.medScreen}) {
      margin-left: ${sizes.paddingSm};
      margin-right: ${sizes.paddingSm};
    } */

  .caption-wrapper {
    color: ${colors.lightGray3};

  }

  .credit-wrapper {
    background: ${colors.text};
    color: ${colors.lightGray3};
  }

    
`

const ProcessTitle = styled.h2`
  font-size: ${sizes.fontHead2};

  @media (max-width: ${sizes.smScreen}) {
    padding: 0 ${sizes.padding1};
  }
`

const Process = props => (
  <ProcessWrapper>
    <ProcessTitle>Process Notes</ProcessTitle>

    {props.children}
  </ProcessWrapper>
)

export default Process
