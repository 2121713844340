import React from 'react'
import styled from 'styled-components'
import sizes from '../_vars/sizes'
import colors from '../_vars/colors'

import Colophon from './colophon'

import '../layouts/global.scss'

const StyledFooter = styled.div`
  background: ${colors.lightGray2};
  color: ${colors.text};
  /* height: 200px; */
  padding: ${sizes.padding1};
  z-index: 2;

  .main-column {
    /* display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
     */

    display: grid;
    justify-content: space-between;

    @media (min-width: 800px) {
      grid-template-columns: calc(33.33% - ${sizes.padding2} / 2) calc(
          33.33% - ${sizes.padding2} / 2
        );
    }
    div {
      /* max-width: 300px;
      min-width: 250px; */

      &:first-of-type {
        margin-right: 1rem;
      }
      &:last-of-type {
        margin-bottom: calc(4 * ${sizes.padding1});
      }
    }
  }

  p {
    font-size: 1rem;
  }

  a {
    color: ${colors.text};
    transition: 0.25s;
    &:hover {
      color: ${colors.accentColorful};
    }
  }
`

const Copyright = styled.p`
  max-width: 35ch;
`
const Footer = ({ className }) => (
  <StyledFooter className={className}>
    <div className="main-column">
      <div>
        <p>
          <a
            href="http://typemedia.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            TypeMedia
          </a>{' '}
          class of 2018
        </p>
        <Copyright>
          All type designs and images are the exclusive copyright of their
          creators.
        </Copyright>
      </div>
      <Colophon />
    </div>
  </StyledFooter>
)
export default Footer
