import React from 'react'
import { MDXProvider } from '@mdx-js/react'

// import each component (these all live in the same 'components' folder)
import ImageRowStack from './image-rowstack.js'
import ImageFullWidth from './image-fullwidth.js'
import Caption from './image-caption.js'
import Credit from './image-credit.js'
import Process from './process-section.js'

// set up a dictionary of imported components
const GlobalScopeComponents = {
  ImageRowStack: ImageRowStack,
  ImageFullWidth: ImageFullWidth,
  Caption: Caption,
  Credit: Credit,
  Process: Process,
}

// export a MDXWrapper component, which will pass these components into the child mdx posts
export const MDXWrapper = ({ children }) => {
  return (
    <MDXProvider components={{ ...GlobalScopeComponents }}>
      {children}
    </MDXProvider>
  )
}
