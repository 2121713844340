import React from 'react'
import styled from 'styled-components'
import sizes from '../_vars/sizes'

const CaptionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @media (min-width: 650px) {
    grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
  }

  @media (max-width: ${sizes.smScreen}) {
    padding: 0 ${sizes.padding1};
  }

  img,
  p img {
    width: 100%;
    height: auto !important;
    background: white;
  }

  p {
    grid-column: auto;
    display: block;
    width: 100%;
    max-width: ${sizes.processTextMax};
    margin: ${sizes.padding05} auto ${sizes.paddingMed};

    @media (min-width: ${sizes.medScreen}) {
      /* margin-left: ${sizes.paddingSm};
      margin-right: ${sizes.paddingSm}; */
    }

    @media (min-width: ${sizes.wideScreen}) {
      &:first-of-type {
        /* margin-top: ${sizes.paddingMed}; */
      }
      &:last-of-type {
        /* margin-bottom: ${sizes.paddingMed}; */
      }
    }
  }
`
// alt={props.alt} removed from <Image> temporarily
const Caption = props => (
  <CaptionWrapper className="caption-wrapper">
    <div>{props.children}</div>
  </CaptionWrapper>
)
export default Caption
