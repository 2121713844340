import React from 'react'

const Colophon = ({ className }) => (
  <div className={className}>
    <p>
      Site designed by <a href="https://dribbble.com/zrin">Zrinka Buljubašić</a>
      , <a href="http://www.katjaschimmel.de/">Katja Schimmel</a>,{' '}
      <a href="http://genramirez.com/">Gen Ramirez</a>,{' '}
      <a href="http://cargocollective.com/namratago">Namrata Goyal</a>,{' '}
      <a href="https://twitter.com/lisalisahuang?lang=en">Lisa Huang</a>, and{' '}
      <a href="https://stephennixon.com/">Stephen Nixon</a> with contributions
      from all of TypeMedia 2018. Site developed by Stephen Nixon.
    </p>
    <p>
      Text and navigation set in{' '}
      <a href="https://www.typotheque.com/fonts/november">November Sans</a>,
      generously donated by <a href="https://www.typotheque.com/">Typotheque</a>
      .
    </p>
  </div>
)
export default Colophon
