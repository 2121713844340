import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import styled, { keyframes } from 'styled-components'
import sizes from '../_vars/sizes'
import colors from '../_vars/colors'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'
import Footer from '../components/footer'
import Pagination from '../components/pagination'
import './global.scss'

import LinkUnderlined from '../components/link--underlined'
import IconLinkArrow from '../components/icon-linkarrow'
import FullHeader from '../components/header--full'
// import Header from '../components/header'
import { MDXWrapper } from '../components/mdx-provider'

import HeroImage from '../components/hero-image'

const StyledPage = styled.div`
  /* display: grid; */
  /* margin-top: calc(${sizes.heroHeight}vh + ${sizes.navBarHeight}); */
  margin-top: ${sizes.navBarHeight};
  z-index: 2;
  background-color: ${colors.lightGray1}; /* medium theme */
  min-height: 100vh;
  padding: 0 1rem;

  @media (max-width: ${sizes.smScreen}) {
    /* padding: 0 ${sizes.padding05}; */
    padding: 0;
  }

  .vsc-controller {
    display: none;
  }
`

const StyledFooter = styled(Footer)`
  z-index: 2;
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
`

const Wrapper = styled.div`
  animation: ${fadeIn} 1s linear forwards;
  display: grid;
  min-height: 100vh;
  width: 100%;
  margin: 0;
`

const MainCol = styled.article`
  margin: 0 auto 5vmax;

  h1,
  h2,
  h3,
  h4 {
    grid-column: 1 / -1;
  }
`

const Intro = styled.header`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0rem ${sizes.paddingMed};
  margin-top: ${sizes.paddingMed};
  margin-bottom: ${sizes.paddingMed};

  @media (max-width: ${sizes.smScreen}) {
    padding: 0 ${sizes.padding1};
  }
  @media (min-width: 900px) {
    grid-template-columns: 3fr 2fr;
  }
  @media (min-width: 1200px) {
    grid-template-columns: 2fr 1fr;
  }

  a {
    text-decoration: none;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  span.separator {
    /* color: ${props => props.accentcolor || colors.accentColorful}; */
    color: ${colors.lightGray4};
  }
`

const ProjectDescription = styled.div`
  @media (min-width: 900px) {
    p {
      font-size: 1.25rem;
      line-height: 2.5rem;
    }
  }
  @media (min-width: 1600px) {
    p {
      font-size: 1.5rem;
      line-height: 2.5rem;
    }
  }
`

const DesignerBio = styled.div`
  p {
    color: ${colors.lightText};
  }
  @media (min-width: 900px) {
    p {
      font-size: 1rem;
      line-height: 2rem;

      &:first-of-type {
        padding-top: 0.5rem;
      }
    }
  }
`

const Project = styled.h1`
  font-size: ${sizes.fontHead2};
  font-weight: 900;
  margin: 1rem 0 1rem;
`
const Designer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: ${sizes.fontHead2};
    font-weight: 400;
    margin: 1rem 0 1rem;
    display: block;
  }
  p {
    display: block;
  }
`
const Origin = styled.span`
  font-size: 1rem;
  font-weight: 700;
  margin: 0.5rem 0 0;
  transform: translateY(calc(${sizes.fontHead2} * 0.25));
  float: right;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  padding: 0.125rem 0.5rem 0.0625rem;
  position: relative;
  top: -12px;
  color: ${colors.text};
  background-color: ${colors.lightGray2};

  svg {
    margin-right: 0.25em;
    /* transform: translateY(0.1em); */
  }
`
const WebLinks = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 1em;
  font-size: 1rem;
  font-weight: 700;

  li {
    display: inline-block;
    padding-right: 1rem;
    line-height: 2;
  }

  a {
    color: ${colors.text};
    transition: color 0.25s ease;

    &:hover {
      color: ${props => props.accentcolor || colors.accentColorful};
    }
  }

  a + span {
    display: inline-block;
    padding-left: 0.125em;
    transition: transform 0.25s;
  }
  a:hover + span {
    /* position: relative; */
    display: inline-block;
    transform: translate(0.25em, -0.25em);
  }
`

const MainContent = styled.section`
  display: grid;
  /* main body area of post */
  & > div {
    display: grid;
  }

  img.gatsby-resp-image-image {
    box-shadow: none !important;
  }
`

export default class ProjectPage extends React.Component {
  render() {
    const mdx = this.props.data.mdx

    // project description

    const desc = mdx.frontmatter.description.split(' ')

    const projectDescriptionWords = desc.map(word => {
      return word === '¶' ? `<span class="separator">${word}</span>` : word
    })
    const projectDescription = projectDescriptionWords.join(' ')

    function createDescription() {
      return { __html: `${projectDescription}` }
    }

    // designer bio

    const firstName = mdx.frontmatter.designer
      .split(' ')[0]
      .toLowerCase()
      .replace('ł', 'l') // support Rafał's name
      .replace('á', 'a') // support Seán's name

    const bio = mdx.frontmatter.bio.split(' ')

    const bioWords = bio.map(word => {
      return word === '¶' ? `<span class="separator">${word}</span>` : word
    })

    const biography = bioWords.join(' ')

    function createBio() {
      return { __html: `${biography}` }
    }

    // social links

    const links = mdx.frontmatter.links

    const socialLinks = links.map((link, index) => (
      <li key={index}>
        <LinkUnderlined>
          <a
            href={link.split(',')[1]}
            target="_blank"
            rel="noopener noreferrer"
            accentcolor={mdx.frontmatter.homepageColor}
          >
            {link.split(',')[0]}
          </a>
          <span>
            {' '}
            <IconLinkArrow color={colors.lightText} />
          </span>
        </LinkUnderlined>
      </li>
    ))

    const prev = this.props.pageContext.prev
    const next = this.props.pageContext.next
    return (
      <MDXWrapper>
        <Wrapper>
          <Helmet>
            <title>
              {mdx.frontmatter.project} | {mdx.frontmatter.designer} | TypeMedia
              2018
            </title>

            <meta
              property="og:title"
              content={`${mdx.frontmatter.project} | ${
                mdx.frontmatter.designer
              } | TypeMedia 2018`}
            />
            <meta
              property="og:description"
              content={`TypeMedia 2018 thesis project by ${
                mdx.frontmatter.designer
              }`}
            />
            <meta
              property="og:image"
              content={`https://www.typemedia2018.com${
                mdx.frontmatter.socialImage.childImageSharp.fixed.src
              }?=release`}
            />
            <meta
              property="og:url"
              content={`https://www.typemedia2018.com/${firstName}`}
            />
            <meta name="twitter:card" content="summary_large_image" />

            <meta property="og:site_name" content="TypeMedia 2018" />
          </Helmet>
          <FullHeader />

          <StyledPage>
            <HeroImage
              alt={mdx.frontmatter.project}
              wide={mdx.frontmatter.featuredImage.childImageSharp.fluid}
              mobile={mdx.frontmatter.featuredImageMobile.childImageSharp.fluid}
            />
            <MainCol className="main-column">
              <Intro>
                <ProjectDescription>
                  <Project>{mdx.frontmatter.project}</Project>
                  <p
                    className="fluid-text"
                    accentcolor={mdx.frontmatter.homepageColor}
                    dangerouslySetInnerHTML={createDescription()}
                  />
                </ProjectDescription>
                <DesignerBio>
                  <Designer>
                    <h2>{mdx.frontmatter.designer}</h2>
                    <Origin className="fluid-caps">
                      {mdx.frontmatter.country}
                    </Origin>
                  </Designer>

                  <p
                    className="fluid-text"
                    accentcolor={mdx.frontmatter.homepageColor}
                    dangerouslySetInnerHTML={createBio()}
                  />
                  <WebLinks accentcolor={mdx.frontmatter.homepageColor}>
                    {socialLinks}
                  </WebLinks>
                </DesignerBio>
              </Intro>
              <MainContent accentcolor={mdx.frontmatter.homepageColor}>
                <MDXRenderer>{mdx.code.body}</MDXRenderer>
              </MainContent>

              <Pagination
                prevLink={prev.fields.slug}
                prevProject={prev.frontmatter.project}
                prevDesigner={prev.frontmatter.designer}
                prevColor={prev.frontmatter.homepageColor}
                nextLink={next.fields.slug}
                nextProject={next.frontmatter.project}
                nextDesigner={next.frontmatter.designer}
                nextColor={next.frontmatter.homepageColor}
              />
            </MainCol>
          </StyledPage>
          <StyledFooter className={this.className} />
        </Wrapper>
      </MDXWrapper>
    )
  }
}

export const pageQuery = graphql`
  query ProjectQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        project
        description
        designer
        country
        bio
        links
        homepageColor
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 2800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        featuredImageMobile {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        socialImage {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      code {
        body
      }
    }
  }
`
