import React from 'react'

const IconMenu = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    stroke={props.color}
    strokeLinecap="square"
    strokeWidth="2"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M 11 5 L 4 5" />
      <path d="M 11 5 L 11 12" />
      <path d="M 10 6 L 2 14" />
    </g>
  </svg>
)
export default IconMenu
