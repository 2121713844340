import React from 'react'
import styled from 'styled-components'
import colors from '../_vars/colors'

const CreditWrapper = styled.div`
  /* grid-column: none; */
  position: absolute;
  transform: translateY(-1em);
  background-color: ${colors.lightGray1};
  display: inline-block;
  padding: 0 0.25em;
  font-style: italic;
  color: ${colors.lightText};
  p {
    font-size: 0.75em;
  }
`

const Credit = props => (
  <CreditWrapper className="credit-wrapper">
    <div>{props.children}</div>
  </CreditWrapper>
)
export default Credit
