import React from 'react'
import styled from 'styled-components'
import sizes from '../_vars/sizes'

const ImageFullWidthWrapper = styled.div`
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  overflow: hidden;
  grid-gap: 1rem;
  margin: ${sizes.padding05} 0;
  /* margin: calc(${sizes.paddingMed} * 0.5) 0; */

  @media (max-width: ${sizes.smScreen}) {
    margin-bottom: ${sizes.padding05};
  }

  img,
  video {
    width: 100%;
    height: auto;
  }

  p {
    margin: 0;
    max-width: 100%;
    margin-bottom: 0;
  }

  .caption-wrapper p {
    /* max-width: 70ch; */
    max-width: ${sizes.processTextMax};
  }

  .caption-wrapper p:not(:last-child) {
    margin-bottom: 1.675em;
  }
`

const ImageFullWidth = props => (
  <ImageFullWidthWrapper>{props.children}</ImageFullWidthWrapper>
)

export default ImageFullWidth
