import React from 'react'
import styled from 'styled-components'
// import Img from 'gatsby-image'
import sizes from '../_vars/sizes'

const ImageRowStackWrapper = styled.div`
  /* border: red solid 4px; */
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 0rem;
  row-gap: ${sizes.padding1};
  margin: ${sizes.padding05} 0;
  /* margin: calc(${sizes.paddingMed} * 0.5) 0; */

  @media (max-width: ${sizes.smScreen}) {
    row-gap: ${sizes.padding05};
    margin: calc(${sizes.padding05} * 0.5 )0;
  }

  @media (min-width: ${sizes.medScreen}) {
    grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
  }

  img,
  p span,
  video {
    width: 100%;
    height: auto !important;
    /* background: white; */
  }

  p {
    grid-column: auto;
    margin: 0;
    display: block;
    max-width: none;
    /* margin: 1rem 0; */
  }

  .caption-wrapper p {
    max-width: ${sizes.processTextMax};
    padding-left: ${sizes.padding1};
    padding-right: ${sizes.padding1};

    @media (max-width: ${sizes.medScreen}) {
      padding-left: 0;
      padding-right: 0;
    }
  }
`

const ImageRowStack = props => {
  // let elements = React.Children.toArray(props.children)
  // console.log(elements)
  return (
    //   <Img fluid={data.placeholderImage.childImageSharp.fluid} />
    <ImageRowStackWrapper className="image-row">
      {props.children}
    </ImageRowStackWrapper>
  )
}
export default ImageRowStack
