import React from 'react'
import Link from 'gatsby-plugin-transition-link'
// import { Link } from './LinkToTop'
import styled from 'styled-components'
import sizes from '../_vars/sizes'
import colors from '../_vars/colors'

const StyledPagination = styled.div`
  display: grid;
  /* grid-template-columns: 1fr; */
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 10vw;
  margin-bottom: 10vw;
  justify-content: space-between;
`

const PageButton = styled(Link)`
  display: grid;
  /* border-radius: 0.75em; */
  transition: 0.25s;
  background: ${colors.accent};
  padding: 1rem;
  text-decoration: none;
  color: ${colors.text};
  min-height: 25vh;
  max-height: 8rem;
  align-content: space-between;
  border-top: solid 0.25rem transparent;

  p {
    max-width: none;
    transition: 0.25s;
  }

  &:last-of-type {
    text-align: right;
    justify-content: flex-end;
  }

  &:hover {
    /* box-shadow: 0px 0px 15px ${colors.lightText}; */
    /* transform: scale(1.05); */

    /* border-top: solid 0.25rem ${colors.accentColorful}; */
    border-top: solid 0.25rem ${props =>
      props.accentcolor || colors.accentColorful};

    p:last-child {
      color: ${colors.accentColorful};
      /* transform: translateX(-0.125em); */
    }
  }

  @media (min-width: 800px) {
    padding: 2rem;
  }
`

const Direction = styled.p`
  font-size: 3rem;
  line-height: 1.5rem;
  color: ${colors.lightText};
  margin: 0;
`
const Project = styled.p`
  font-size: 1em;
  font-weight: 700;
  margin: 0;
  @media (min-width: 800px) {
    font-size: ${sizes.fontHead2};
  }
`
const Designer = styled.p`
  font-size: 1em;
  margin: 0;
  @media (min-width: 800px) {
    font-size: 1.25rem;
  }
`

const Pagination = props => {
  return (
    <StyledPagination>
      <PageButton to={`/${props.prevLink}/`} accentcolor={props.prevColor}>
        <div>
          <Project>{props.prevProject}</Project>
          <Designer className="fluid-text">{props.prevDesigner}</Designer>
        </div>
        <Direction>←</Direction>
      </PageButton>
      <PageButton to={`/${props.nextLink}/`} accentcolor={props.nextColor}>
        <div>
          <Project>{props.nextProject}</Project>
          <Designer className="fluid-text">{props.nextDesigner}</Designer>
        </div>
        <Direction>→</Direction>
      </PageButton>
    </StyledPagination>
  )
}
export default Pagination
